import React from "react"

import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import Modal from "react-bootstrap/Modal"

// import { library } from "@fortawesome/fontawesome-svg-core"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faEdit } from "@fortawesome/free-solid-svg-icons"
// library.add(faEdit)

class MyModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>公司簡介</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // paddingLeft: "0px",
          }}
        >
          {/* <Col xs={0} sm={0} lg={4}>
            <img
              className="historyPic"
              style={{ height: "auto", width: "100%" }}
              src="/img/download.png"
              alt=""
            />
          </Col>
          <Col xs={12} sm={12} lg={8}> */}
          <Col>
            <br />
            <p style={{ fontSize: "20px" }}>
              富高娛樂製作公司於1989年由從事多年製作，並深耕於演藝娛樂及推廣行業，擁有豐富經驗的麥潤壽先生,
              BBS, MH 成立。
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              多年來，麥潤壽先生帶領團隊以專業知識和經驗，為本地及跨國企業提供全面的設計及顧問服務，並統籌舉辦各種活動。
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              憑藉麥潤壽先生多年從事製作的豐富經驗和活動策劃領域中的廣博知識，以及富有創意的團隊，富高娛樂製作公司全力以赴，將為您提供活動策劃的最佳建議和創意。
            </p>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }
}

class CompanyHistoryModal extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = { modalShow: false }
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false })

    return (
      <ButtonToolbar style={{ paddingLeft: "10vw" }}>
      <Button
          onClick={() => this.setState({ modalShow: true })}
          style={{
            backgroundColor: "transparent",
            border: "solid white 1px",
            width: "100px",
            // borderRadius: "50%",
          }}
          // style={{
          //   backgroundColor: "transparent",
          //   border: "none",
          //   borderRadius: "50%",
          // }}
        >
          簡介
          {/* <FontAwesomeIcon
            className="historyIcon"
            icon="edit"
            style={{ color: "white", fontSize: "100px" }}
          /> */}
        </Button>

        <MyModal show={this.state.modalShow} onHide={modalClose} />
      </ButtonToolbar>
    )
  }
}

export default CompanyHistoryModal
