import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Service = () => {
  return (
    <div className="serviceBox">
      <Container>
        <Row>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src="/img/conceptDesign.jpg" alt="Concept Design" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                從年度晚宴到產品發佈，我們可以協助您設計和創建活動讓您傳遞預期的信息
              </p>
            </div>

            <h3>概念設計</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/stageVenueDecoration.jpg"
                alt="Stage and Venue Decoration"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                {" "}
                <br />
                通過我們為您度身訂造的設計方案佈置您的場地，
                為您的賓客提供難忘的體驗
              </p>
            </div>

            <h3>舞台和場地佈置</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src="/img/programLogistics.jpg" alt="Program Logistics" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                規劃與執行同等重要，我們在行業中的豐富經驗
                將確保活動協調良好地完成
              </p>
            </div>

            <h3>活動流程</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/audiovisualEffects.jpg"
                alt="audiovisual Effect"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                從燈光，音響，視頻到舞台冷煙花，我們確保觀眾獲得最佳的視覺享受
              </p>
            </div>

            <h3>視聽方案</h3>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Service
