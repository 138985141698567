import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

export default class ContactUs extends React.Component {
  state = {
    email: "",
    name: "",
    about: "一般查詢",
    comment: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = async event => {
    event.preventDefault()
    alert(`${this.state.name}，多謝您的查詢，我們會盡快聯絡您。`)
    const { name, email, about, comment } = this.state
    const res = await fetch(
      `/send_email?name=${name}&email=${email}&comment=${comment}&about=${about}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const result = await res.json()
    console.log(result);
  }

  render() {
    return (
      <div id="contactUs">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginTop: "-126px",
            width: "100%",
            height: "100px",
            color: "white",
            //  transform: "rotate(180deg)",
          }}
          preserveAspectRatio="none"
          viewBox="0 0 200 100"
        >
          <path fill="currentColor" d="M0,0L200,100L0,100z" />
        </svg>
        <Container>
          <div className="sectionHeader">
            <Row noGutters>
              <Col>
                <h2> 聯繫我們 </h2>
              </Col>
            </Row>
          </div>
          <div className="sectionSubHeader">
            <Row noGutters>
              <Col>
                <h3>讓我們為您提供合適的服務</h3>
              </Col>
            </Row>
          </div>
          <div
            className="sectionContent"
            style={{ boxShadow: "0px 0px 20px grey" }}
          >
            <Row noGutters>
              <Col>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>電子郵件地址</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicName">
                    <Form.Label>名稱</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="陳大文"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicAbout">
                    <Form.Label>關於</Form.Label>
                    <Form.Control
                      as="select"
                      name="about"
                      value={this.state.about}
                      onChange={this.handleInputChange}
                    >
                      <option>一般查詢</option>
                      <option>概念設計</option>
                      <option>舞台和場地佈置</option>
                      <option>活動流程</option>
                      <option>視聽方案</option>
                      <option>其他</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formBasicComment">
                    <Form.Label>意見</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="comment"
                      value={this.state.comment}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    style={{ width: "100%", backgroundColor: "#413C58" }}
                    onClick={this.handleSubmit}
                  >
                    提交
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}
