import React from "react"

const serviceAccordion = () => {
  return (
    <div className="serviceAccordion">
      <ul>
        <li>
          <div>
            <a href="#services">
              <h3>概念設計</h3>
              <h4>
                <br />
                從年度晚宴到產品發佈，
                <br />
                我們可以協助您設計和創建活動
                <br />
                讓您傳遞預期的信息
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>舞台和場地佈置</h3>
              <h4>
                <br />
                通過我們為您度身訂造的
                <br />
                設計方案佈置您的場地，
                <br />
                為您的賓客提供難忘的體驗
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>活動流程</h3>
              <h4>
                <br />
                規劃與執行同等重要，
                <br />
                我們在行業中的豐富經驗
                <br />
                將確保活動協調良好地完成
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>視聽方案</h3>
              <h4>
                <br />
                從燈光，音響，視頻到
                <br />
                舞台冷煙花，我們確保
                <br />
                觀眾獲得最佳的視覺享受
              </h4>
            </a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default serviceAccordion
