import React from "react"

import "./service.scss"

import ServiceAccordion from "./serviceAccordionTC"
import ServiceBox from "./serviceBoxTC"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Service = () => {
  return (
    <div id="services" style={{ backgroundColor: "#413C58", color: "white" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          // display: "block",
          marginTop: "-126px",
          width: "100%",
          height: "100px",
          color: "#413C58",
          // transform: "rotate(180deg)",
        }}
        preserveAspectRatio="none"
        viewBox="0 0 200 100"
      >
        <path fill="currentColor" d="M0,0L200,100L0,100z" />
      </svg>
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <h2> 服務範圍 </h2>
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <h3>我們提供全方位服務以確保活動成功舉辦</h3>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <Row noGutters>
            <ServiceAccordion />
            <ServiceBox />
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Service
